"use client";

import { FC, Fragment, cloneElement, useContext } from "react";

import { PostProps } from "./types";
import PostStyledComponents from "./styled";

import { PostContext } from "../PostProvider";

const { Container } = PostStyledComponents;

const Post: FC<PostProps> = ({ data }) => {
  const commonComponentValues = useContext(PostContext);

  return (
    <Container>
      {data.rows.map(({ id, item, props }) => {
        const componentValue = commonComponentValues.find(
          (componentValue) => componentValue.title === item.title,
        );

        if (componentValue) {
          const { component } = componentValue;
          return cloneElement(component, { ...props, key: id });
        } else {
          return <Fragment key={id} />;
        }
      })}
    </Container>
  );
};

export default Post;
