import styled from "styled-components";

import {
  sideMargin,
  tabletBreakPoint,
  transitionDelay,
} from "../../constants/size";

const PostStyledComponents = {
  Container: styled.div`
    width: 1080px;
    padding: 120px 0;
    margin: auto;

    transition: ${transitionDelay}s;

    @media (max-width: ${tabletBreakPoint}px) {
      width: 100%;
      padding: ${sideMargin}px;
    }
  `,
};

export default PostStyledComponents;
